.pricing-card{
    box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.0784313725490196);
    border: unset;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover{
        box-shadow: 0px 20px 60px 0px rgba(1, 30, 81, 0.2);
    }
    h2{
        margin-bottom: 30px;
    }
    &__list{
        margin-bottom: 40px;
        li{
            padding:12px 0;
            border-bottom: 1px solid #e7e7e7;

            &::after{
                display: none;
            }

            &:last-child{
                border-bottom: unset;
                padding-bottom: 0;
            }
        }
    }

    .card-body{
        padding: 40px 30px;
    }

    &__amount{
        color: $secondary-color;
        font-weight: 600;
        margin-block: 30px;
        // font-size: 40px;

        sup{
            font-size: 16px;
            top: -1.5em !important;
            margin-right: 5px;
        }
        .value{
            display: inline-block;
            font-size: 40px;
            line-height: 1;
        }
        .duration{
            margin-left: 5px;
        }
    }
}