.contact-modal {
    .modal-body {
        padding: 60px 20px;
    }

    .close-btn {
        background: transparent;
        border: unset;
        outline: none;
        color: $primary-color;
        width: 20px;
        height: 20px;
        padding: 0;
        display: inline-block;
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        z-index: 1;
    }

    &__header {
        margin-bottom: 30px;
        text-align: center;

        h2 {
            font-weight: 400;
        }
    }

    .form-control {
        background-color: $bg;
        padding: 6px 20px;
        height: 50px;

        &::placeholder {
            color: $text;
        }
    }
    .message-group{
        margin-bottom: 30px !important;

        textarea {
            min-height: 100px;
            resize: none;
        }
    }

    .small-loader{
        justify-content: center;
    }
    
}

//video-modal

.video-modal {
    max-width: 300px;
    margin: auto;
    top: 50%;
    transform: translateY(-50%) !important;

    @media screen and (min-width: $min-md) {
        max-width: 700px;
    }

    .close-btn {
        background: transparent;
        border: unset;
        outline: none;
        color: $white;
        width: 20px;
        height: 20px;
        padding: 0;
        display: inline-block;
        position: absolute;
        top: -55px;
        right: -15px;
        cursor: pointer;
        z-index: 1;

        @media screen and (min-width: $min-md) {
            top: -15px;
            right: -40px;
        }
    }

    .modal-body {
        min-height: 60vh !important;
        padding-bottom: 56.24%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}