.solutions-block {
    position: relative;
}

// sticky-bar

.sticky-bar {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: $white;
    z-index: 6;
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        width: 100%;
        text-align: center;
        position: relative;
        padding: 26px 0;
        display: block;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        
        @media screen and (min-width: $min-md) {
            width: 25%;
        }
        .text {
            color: $primary-color !important;
        }
        &:hover {
            background-color: $primary-color;
            .text {
                color: $white !important;
            }
        }

        &.active {
            // transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            background-color: $primary-color;
            .text {
                color: $white !important;
            }
        }


    }

    .text {
        font-size: 16px;
        line-height: 1;
        color: $secondary-color;
        font-weight: 600;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

    }
}

//image-text

.image-text {
    padding: 60px 0;

    @media screen and (min-width: $min-md) {
        padding: 110px 0;
    }

    &:not(:first-of-type) {
        padding-top: 0;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &.reverse {
        .container {
            flex-direction: row-reverse;
        }
    }

    &__content {
        width: 100%;

        @media screen and (min-width: $min-md) {
            width: 43%;
        }
    }

    &__image {
        width: 100%;
        height: 100px;
        box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.0784313725490196);
        border-radius: 10px;
        border: 1px solid #011E511A;
        overflow: hidden;

        @media screen and (min-width: $min-md) {
            width: 53%;
            height: 450px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__list {
        // max-width: 280px;
        list-style: none;
        margin: 30px 0;
        padding-left: 0;

        li {
            background-color: $bg;
            padding-block: 8px;
            margin-bottom: 12px;
            border-radius: 10px;
            padding-left: 40px;

            &::after {
                content: "";
                position: absolute;
                left: 15px;
                top: 18px;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $primary-color;
            }
        }
    }
}

.getstarted-block {
    padding-top: 100px;
    padding-bottom: 40px;
}