.professional-journey {
    padding-block: 50px 100px;
}

.journey-card {
    display: flex;
    position: relative;
    cursor: default;
    flex-wrap: wrap;
    flex-flow: column-reverse;
    margin-bottom: 50px;

    @media screen and (min-width: $min-lg) {
        flex-flow: unset;
        margin-bottom: 0;

    }

    &__info {
        width: 100%;

        @media screen and (min-width: $min-lg) {
            border-left: 2px solid transparent;
            border-right: 1px solid #e7e7e7;
            padding-right: 60px;
            padding-bottom: 30px;
            width: 50%;
        }

        &__wrapper {
            background-color: $bg;
            position: relative;
            padding: 50px 20px;
            text-align: left;
            border-radius: 16px;
            transition: all 0.3s ease;

            @media screen and (min-width: $min-lg) {
                text-align: right;
                padding: 50px;
                padding-right: 130px;
            }

            &::after {

                @media screen and (min-width: $min-lg) {
                    content: '';
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    top: 50%;
                    right: -20px;
                    transform: translateY(-50%)rotate(45deg);
                    background-color: $bg;
                    transition: all 0.3s ease;
                }

            }
        }
    }

    &__time {
        width: 50%;
        display: flex;
        padding: 15px;
        // align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        @media screen and (min-width: $min-lg) {
            padding: 35px;
            border-left: 1px solid #e7e7e7;
            border-right: 2px solid transparent;
        }
    }

    &__subtitle {
        display: block;
        color: $primary-color;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .date {
        display: block;
        font-size: 18px;
        font-weight: 500;
    }

    .journey-count {
        display: block;
        font-size: 18px;
        font-weight: 400;
        color: $text;
        font-size: 16px;
    }

    &__icon {
        position: absolute;
        top: 25px;
        right: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
        color: $white;
        width: 25px;
        height: 25px;
        border-radius: 6px;
        font-size: 26px;

        @media screen and (min-width: $min-lg) {
            border-radius: 16px;
            top: 50px;
            right: 50px;
            width: 50px;
            height: 50px;
        }

        svg {
            width: 14px !important;
            height: 14px !important;

            @media screen and (min-width: $min-lg) {
                width: 24px !important;
                height: 24px !important;
            }

        }
    }

    &::after {
        @media screen and (min-width: $min-lg) {

            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            border: 2px solid $primary-color;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $white;
            z-index: 1;
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $primary-color;
        z-index: 2;
        opacity: 0;
    }

    &:hover {
        &::before {
            opacity: 1;
        }

        .journey-card__info__wrapper {
            background-color: $primary-color;
            color: $white;

            p {
                color: $white;
            }

            &::after {
                background-color: $primary-color;
            }
        }

        .journey-card__subtitle {
            color: $white;
        }

        .journey-card__icon {
            color: $primary-color;
            background-color: $white;
        }

        .journey-card__title {
            color: $white;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    &.reverse {
        @media screen and (min-width: $min-lg) {
            flex-direction: row-reverse;

        }

        .journey-card__info {
            @media screen and (min-width: $min-lg) {
                padding-right: 0;
                padding-left: 60px;
                border-left: 1px solid #e7e7e7;
                border-right: 2px solid transparent;
            }
        }

        .journey-card__time {
            @media screen and (min-width: $min-lg) {
                border-left: 1px solid transparent;
                border-right: 1px solid #e7e7e7;
                text-align: right;
            }
        }

        .journey-card__info__wrapper {
            @media screen and (min-width: $min-lg) {
                padding-right: 50px;
                padding-left: 130px;
                text-align: left;
            }

            &::after {
                @media screen and (min-width: $min-lg) {

                    right: auto;
                    left: -20px
                }
            }
        }

        .journey-card__icon {
            @media screen and (min-width: $min-lg) {
                right: auto;
                left: 50px;
            }
        }
    }
}