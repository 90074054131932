.news-block{
    padding-bottom: 80px;

    &__wrapper{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.news-card{
    &__main{
        width: 100%;
        margin-bottom: 30px;
        @media screen and (min-width: $min-lg) {
            width: 60%;
            margin-bottom: 0;
        }

        &__link{
            display: block;
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            height: 100%;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 70%;
                left: 0;
                bottom: 0;
                background: linear-gradient(rgba(30, 13, 50, 0) 0%, rgba(1, 31, 83, 0.99) 100%);
            }

            &:hover{
                .news-card__img{
                    img{
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    &__img{
        width: 100%;
        height: 100%;
        min-height: 400px;
        @media screen and (min-width: $min-md) {
            min-height: 500px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease;
        }
    }

    &__small{
        width: 100%;
        @media screen and (min-width: $min-lg) {
            width: 38%;
        }
    }

    &__mini{
        display: block;
        padding-block: 10px;
        border-top: 1px solid rgba(1, 30, 81, 0.1019607843);

        &:first-child{
            padding-top: 0;
            border-top: unset;
        }

        &:last-child{
            padding-bottom: 0;
        }

        &__wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &:hover{
            .mini-image{
                img{
                    transform: scale(1.1);
                }
            }
            .mini-content{
                h2{
                    color: $primary-color;
                }
            }
        }

        .mini-image{
            width: 150px;
            height: 120px;
            margin-right: 20px;
            border-radius: 10px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                transition: all 0.3s ease;
            }
        }

        .mini-content{
            width: calc(100% - 170px);
            h2{
                margin-bottom: 0;
                font-size: 16px;
                line-height: 1.5;
                font-weight: 600;
                @media screen and (min-width: $min-md) {
                    font-size: 18px;
                }
            }
        }


    }

    &__content{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        z-index: 1;

        .author{
            display: inline-block;
            color: $white;
            font-size: 12px;
        }
    }

    &__source{
        color: $white;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        .source-title{
            display: inline-block;
            margin-right: 5px;
        }
        .source-info{
            display: inline-block;
            margin-right: 10px;
        }
        .date{
            display: inline-block;
        }
    }

    &__title{
        color: $white;
        font-weight: 600;
        font-size: 36px;
        line-height: 1.2;
        margin-bottom: 20px;
        @media screen and (min-width: $min-md) {
            font-size: 40px;
        }
        @media screen and (min-width: $min-lg) {
            font-size: 50px;
        }
    }
    
}