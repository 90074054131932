.key-benefits {
    background-color: $bg;
    padding-block: 50px;

    @media screen and (min-width: $min-md) {
        padding-block: 100px;
    }
    .mini-text{
        background-color: #936BFF1A;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__content {
        width: 100%;
        margin-bottom: 50px;

        @media screen and (min-width: $min-lg) {
            width: 38%;
        }

        li{
            &::after{
                top: 9px !important;
            }
        }
    }

    .feature-count {
        width: 100%;

        @media screen and (min-width: $min-lg) {
            width: 50%;
        }
    }
}

.feature-count{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    @media screen and (min-width: $min-md) {
        grid-template-columns: repeat(2, 1fr);
    }

    &__card{
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.08);
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // border: 1px solid #d9d9d9;
    }

    &__count{
        font-size: 42px;
        font-weight: 600;
        color: $title;
        margin-bottom: 10px;
        line-height: 1.2;
    }

    &__description{
        font-size: 15px;
        font-weight: 600;
        color: $title;
        display: block;
        letter-spacing: -0.015em;
    }
}