.our-teams {
    padding-block: 50px 100px;

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;

        @media screen and (min-width: $min-md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: $min-lg) {
            grid-template-columns: repeat(3, 1fr);

        }
    }
}


.teams-card {
    height: 300px;
    overflow: hidden;
    border-radius: 16px;
    border: 1px solid #e5dddd;

    @media screen and (min-width: $min-md) {
        height: 380px;

    }

    &__wrapper {
        height: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #181B21;
            opacity: 0;
            transition: all 0.3s ease;
            z-index: 1;
        }

        &:hover {
            &::after {
                opacity: 0.4;
            }

            .teams-card__info {
                opacity: 1;
                transform: translateY(-40px);
            }
        }
    }

    &__info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 40%;
        z-index: 2;
        padding: 30px;
        text-align: center;
        transform: translateY(0%);
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }

    &__member-name {
        color: $white;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        letter-spacing: -0.027em;
        margin-bottom: 10px;
    }

    .position {
        color: $white;
        font-size: 16px;
    }

    &__social-links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    &__link {
        color: $white;
        background-color: $primary-color;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px;

        img {
            object-fit: contain;
            height: 16px;
            width: 16px;
        }

        svg {
            width: 16px;
            height: 16px;
            object-fit: contain;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}