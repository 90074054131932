@import './../../../assets/scss/variable.scss';
@import './../../../assets/scss/commons.scss';

.side-menu{
    position: fixed;
    z-index: 3;
    background-color: $white;
    // height: calc(100vh - 70px);
    top: 70px;
    left: auto;
    right: -100%;
    // bottom: 0;
    border-top: 1px solid lightblue;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    transition: all 0.5s ease-in-out;

    &.active{
        right: 0;
        left: 0;
    }

    @media screen and (min-width:$min-md){
        display: none;
    }

    &__item{
        font-size: 24px !important;
        margin: 10px 0;

    }
}
