.contact-info {
    background-color: $bg;
    padding-block: 100px 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.0705882353);

    .container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 50px;

        @media screen and (min-width: $min-lg) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .sub-subtitle {
        // color: $white;
    }

    .address-block {
        // color: $white;

        span {
            display: block;
        }

        &__addresses {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .address {
            width: 100%;

            @media screen and (min-width: $min-md) {
                width: 45%;

            }
        }
    }

    .subtitle {
        // color: $white;
        margin-bottom: 32px;
    }

    .contact-information {
        a {
            // color: $white;
            display: block;

            &:hover {
                text-decoration: underline !important;
            }
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .phone-wrapper {
            display: block;
            width: 100%;
            margin-bottom: 20px;

            @media screen and (min-width: $min-md) {
                width: 45%;

            }
        }

        .mail-wrapper {
            display: block;
            width: 100%;

            @media screen and (min-width: $min-md) {
                width: 45%;

            }
        }
    }
}