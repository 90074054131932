.blog-slider {
    padding-block: 50px;
    // background-color: $secondary-color;
}

//card
.blog-slide {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    transition: all 0.3s ease;
    margin: 20px 18px;

    @media screen and (min-width: 760px) {
        margin: 60px 18px;
        min-height: 380px;
    }

    &:hover {
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3);
    }

    &__link {
        cursor: pointer;
    }

    &__img {
        overflow: hidden;
        height: 160px;

        @media screen and (min-width: 760px) {
            height: 200px;
        }

        img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
            object-position: center center;
        }
    }

    &__content {
        padding: 20px;
    }

    .post-time {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
            display: inline-block;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
            color: $primary-color;
        }

        .post-date {
            margin-right: 6px;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .post-writer {
        display: block;
        font-size: 12px;
        font-weight: 600;
        color: $primary-color;
    }

    &__social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        &__title {
            font-size: 14px;
            display: block;
            color: $secondary-color;
            font-weight: 500;
            width: 100%;
        }

        &__wrapper {
            .mini-text {
                margin-right: 15px;
            }
        }
    }

    .share-btn {
        border: unset;
        outline: unset;
        background: transparent;
        width: max-content;
        height: max-content;
        padding: 0;
    }
}

.blog-slider-wrapper {
    max-width: $min-xxl;
    width: 100%;
    padding: 0 20px;
    margin-inline: auto;

    @media screen and (min-width: 760px) {
        padding: 0 50px;
    }

    .slick-prev,
    .slick-next {
        color: $secondary-color;
        background-color: $primary-color;
        // background-color: #f1f1f1b5;
        width: 40px;
        height: 40px;
        z-index: 1;
        border-radius: 50%;

        @media screen and (min-width: 760px) {
            width: 50px;
            height: 50px;
        }
    }

    .slick-prev {
        left: -10px;
    }

    .slick-next {
        right: -10px;
    }
}