.blog-page-body {
    padding: 50px 0;

    @media screen and (min-width: $min-md) {
        padding: 40px 0;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__main-content {
        width: 100%;

        @media screen and (min-width: $min-lg) {
            border-left: 1px solid #e7e7e7;
            width: 68%;
            padding-left: 30px;
        }
    }

    .blogs-column {
        width: 100%;
        padding-top: 50px;

        @media screen and (min-width: $min-lg) {
            width: 30%;
            padding-top: 0;
        }
    }
}

.search-box {
    margin-bottom: 50px;

    .form-control {
        z-index: 0;
    }
}

.blogs-categories {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .category-link {
        display: inline-block;
        color: $primary-color;
        font-size: 11px;
        border-radius: 10px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 11px;
        letter-spacing: 0.04em;
        padding: 9px 12px;
        background-color: $bg;
        margin-right: 10px;
        margin-bottom: 10px;
        transition: all 0.3s ease;
        border: unset;
        outline: unset;
        cursor: pointer;

        &:hover {
            color: $white;
            background-color: $primary-color;
        }
    }
}

.recent-post-card {
    margin-bottom: 20px;

    &__link {
        display: flex;
        justify-content: space-between;
        color: $secondary-color;

        &:hover {
            p {
                color: $primary-color;
            }

            .recent-post-card__img {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &__img {
        width: 40%;
        border-radius: 10px;
        overflow: hidden;
        // height: 94px;


        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
        }
    }

    &__content {
        width: 60%;
        padding: 10px 20px;
    }

    p {
        font-weight: 600;
    }
}

.search-box {
    button {
        padding: 12px 18px;

        &:hover {
            color: white;
            background-color: $secondary-color;
        }
    }

    svg {
        width: 20px;
        height: 20px;
    }
}

.blog-single {
    padding-block: 100px;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;
        padding-bottom: 100px;
    }

    &__content {
        width: 100%;

        @media screen and (min-width: $min-lg) {
            padding-right: 30px;
            width: 68%;
        }

        &__img-box {
            width: 100%;
            height: 300px;
            overflow: hidden;
            border-radius: 10px;
            margin-block: 50px;

            @media screen and (min-width: $min-lg) {
                height: 500px;
            }

            @media screen and (min-width: $min-lg) {
                height: 600px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__sidebar {
        width: 100%;
        background-color: $white;
        padding-top: 100px;

        @media screen and (min-width: $min-lg) {
            padding-top: 0;
            width: 30%;
            position: sticky;
            top: 80px;
        }
    }
}

.related-posts {
    border-top: 1px solid #e7e7e7;
    padding-top: 60px;


    &__wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;

        @media screen and (min-width: $min-md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: $min-lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .subtitle {
        margin-bottom: 40px;
    }

    .blog-slide {
        margin: 0;
    }
}

.error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}