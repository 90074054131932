.contact {    
    @media screen and (min-width: $min-xl) {
        padding-left: calc((100vw - $min-xxl) / 2);
    }

    &__inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    
}

.contact-form {
    width: 100%;
    padding-block:60px;
    padding-inline: 20px;
    @media screen and (min-width: $min-lg) {
        width: 50%;
        padding-right: 100px;
        padding-block:80px;
    }

    &__top{
        margin-bottom: 30px;
    }

    .form-control{
        background-color: $bg;
        padding: 6px 20px;
        height: 50px;

        &::placeholder{
            color: $text;
        }
    }

    textarea{
        min-height: 150px;
    }
    .small-loader{
        justify-content: center;
    }

}
.contact-map{
    width: 100%;
    height: 600px;
    @media screen and (min-width: $min-lg) {
        width: 50%;
        // margin-right: calc((50% + 20px) - 50vw);
        margin-left: 0;
        height: 820px;
    }
}
.marker {
    width: 20px;
    height: 20px;
    border: 2px solid red; /* Change border color as needed */
    border-radius: 50%;
    background: transparent; /* Transparent background */
  }