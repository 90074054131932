.visuals{
    position: relative;
    padding: 40px 0;
    @media screen and (min-width: $min-lg) {
        padding: 80px 0 60px;
    }
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__bg{
        background-color: $secondary-color;
        position: absolute;
        z-index: -1;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;

        .pattern{
            display: none;
            width: 30%;
            height: 100%;
            background-color: $new-yellow;
            @media screen and (min-width: $min-lg) {
                display: block;
            }
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__content{
        width: 100%;
        @media screen and (min-width: $min-lg) {
            width: 40%;
            p{
                max-width: 400px;

                &:last-of-type {
                  margin-bottom: 40px !important;
                }
            }
        }
        
        h2, p{
            // color: white;
        }
    }

    &__graphic{
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        height: 360px;
        margin-top: 40px;

        @media screen and (min-width: $min-lg) {
            width: 52%;
            height: 600px;
            margin-top: 0;
        }
        img{
            width: 100%;
            height: 100%;
        }   

    }
}



.image-filter {
    // background-color: aqua;
    animation-duration: .5s !important;
    max-width: 100%;
    box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.08);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    box-sizing: content-box;
    display: flex;
    height: 500.375px;

    img {
        border: none;
        max-width: 100%;
        position: absolute;
        top: 0;
        display: block;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
    }

    .twentytwenty-overlay {
        -webkit-transition-property: background;
        transition-property: background;
        background: 0 0;
        z-index: 25;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .twentytwenty-before-label {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        &::before {
            color: $secondary-color;
            background-color: #FFFFFF;
            margin: -19px 0px 0px 0px;
            top: 50%;
            border-radius: 10px;
            font-weight: 600;
            content: attr(data-content);
            position: absolute;
            background: rgba(255, 255, 255, .2);
            line-height: 38px;
            padding: 0 20px;
            font-size: 13px;
        }
    }

    .twentytwenty-after-label {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        &::before {
            color: $secondary-color;
            background-color: #FFFFFF;
            margin: -19px 0px 0px 0px;
            right: 10px;
            top: 50%;
            border-radius: 10px;
            font-weight: 600;
            content: attr(data-content);
            position: absolute;
            background: rgba(255, 255, 255, .2);
            line-height: 38px;
            padding: 0 20px;
            font-size: 13px;
        }
    }

    .twentytwenty-handle {
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #fff;
        border-color: transparent;
        -webkit-transition: color .3s, background-color .3s;
        transition: color .3s, background-color .3s;
        z-index: 40;
        cursor: pointer;
        top: 50%;
        margin-left: -22px;
        margin-top: -22px;
        border: 3px solid #fff;
        border-radius: 1e3px;
        height: 38px;
        width: 38px;
        position: absolute;

        &::after {
            box-shadow: none;
            top: 50%;
            margin-top: 22px;
            width: 3px;
            height: 9999px;
            left: 50%;
            margin-left: -1.5px;
            content: " ";
            display: block;
            background: #fff;
            position: absolute;
            z-index: 30;
        }

        &::before {
            box-shadow: none;
            bottom: 50%;
            margin-bottom: 22px;
            width: 3px;
            height: 9999px;
            left: 50%;
            margin-left: -1.5px;
            content: " ";
            display: block;
            background: #fff;
            position: absolute;
            z-index: 30;
        }

        .twentytwenty-left-arrow {
            border-right: 6px solid $primary-color;
            left: 50%;
            margin-left: -17px;
            top: 50%;
            margin-top: -6px;
            width: 0;
            height: 0;
            border: 6px inset transparent;
            position: absolute;
        }

        .twentytwenty-right-arrow {
            border-left: 6px solid $primary-color;
            right: 50%;
            margin-right: -17px;
            top: 50%;
            margin-top: -6px;
            box-sizing: content-box;
            width: 0;
            height: 0;
            border: 6px inset transparent;
            position: absolute;
        }
    }
}