.center-content {
    // max-width: 530px;
    text-align: center;
    margin-bottom: 60px;

    h2 {
        color: $title;
    }

    color: $text;

    p {
        max-width: 530px;
        margin-inline: auto;

    }
}