$font-inter: "Inter", sans-serif;
$font-outfit: "Outfit", sans-serif;

//colors

$white: #ffff;
$black: #000;
$mid-black:#222222;
$orange: #D7B05B;
$blue:#45ABE1;
$light-blue:#B2DFDB;

$grey:#011e511a;
$bg-grey:#f4f9fb;
$bg:#FAFAFA;
$primary-color:#141413;
$secondary-color:#fafaf8;
$new-yellow: #df9b62;
// $primary-color:#607D8B;
// $secondary-color:#ECEFF1;

$text:#141413;
$title:#141413;
$text-grey:#757575;

//sizes

$min-sm:576px;
$min-md:768px;
$min-lg:992px;
$min-xl:1240px;
$min-xxl:1400px;
$min-xxxl:1600px;
$min-uw:1600px;
