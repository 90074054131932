.footer{
    background-color: $primary-color;

    &__nav{
        padding-block: 30px;
        border-bottom: 1px solid #ffffff12;

        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
        &__left{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 20px;
            @media screen and (min-width: $min-lg) {
                justify-content: flex-start;
                margin-bottom: 0;
                width: 40%;
            }
        }
        &__right{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            @media screen and (min-width: $min-lg) {
                justify-content: flex-end;
                width: 60%;
            }
        }
        &__link{
            margin-right: 10px;
        }
    }
    ul{
        list-style: none;
        padding-left: 0;
    }
    &__wrapper{
        padding-block: 80px;
        border-bottom: 1px solid #ffffff12;
        .sub-subtitle{
            color: $white;
            margin-bottom: 26px;
        }
        ul{
            li{
                margin-bottom: 10px;
                padding: 0;
                &::after{
                    display: none;
                }
            }
        }
        .container{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__main{
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        @media screen and (min-width: $min-lg) {
            width: 25%;
            text-align: left;

        }
        img{
            height: 30px;
            margin-bottom: 26px;
        }
        p{
            color: $white;
            max-width: 340px;
            margin-inline: auto;
            @media screen and (min-width: $min-lg) {
                margin-inline: 0;
            }
        }

    }
    &__row{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (min-width: $min-lg) {
            width: 70%;
        }

        &__column{
            width: 100%;
            margin-bottom: 30px;
            text-align: center;
            
            @media screen and (min-width: $min-md) {
                // width: 33.33%;
                text-align: left;

                padding-inline:10px;
            }
            // @media screen and (min-width: $min-lg) {
            //     width: 25%;
            // }
        }
    }
    &__feature{
        @media screen and (min-width: $min-md) {
            width: 35%;
        }
    }
    &__company{
        @media screen and (min-width: $min-md) {
            width: 20%;
        }
        
    }
    &__newsletter{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: $min-md) {
            width: 40%;
        }

        h3{
            margin-bottom: 0 !important;
        }
    }
    &__copyright{
        padding-block: 30px;
        .container{
            display: flex;
            justify-content: center;
        }

        .large-paragraph{
            color: $white;
        }
    }
}

.social-media{
    &__link{
        text-align: center;
        text-decoration: none;
        padding: 0px 12px 0px 12px;
        line-height: 36px;
        color: $white;
        background-color: #FFFFFF1A;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        border-radius: 10px;
        margin-right: 10px;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 10px;


        img, svg{
            width: 20px;
            height: 20px;
            // padding-right: 5px;
        }

        &:hover{
            // color: $primary-color;
            transform: translateY(-5px);
            background-color: $primary-color;
        }
    }
}