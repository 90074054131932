.how-it-work {
    padding-block: 100px 40px;

    &__top {
       
    }

    &__grid {
        display: grid;
        border-radius: 10px;
        border: 1px solid #011e511a;
        overflow: hidden;
        grid-template-columns: repeat(1, 1fr);
        @media screen and (min-width: $min-md) {
            grid-template-columns: repeat(3, 1fr);
            
        }
        box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.0784313725490196);

    }
}

.work-card {
    border-radius: 0 !important;
    cursor: pointer;
    background-color: $bg;
    position: relative;
    padding: 20px;
    @media screen and (min-width: $min-md) {
        border-right: 1px solid #011e511a;
        padding: 30px;
    }
    @media screen and (min-width: $min-lg) {
        padding: 50px;
    }

    &:last-child {
        border-right: 0;

        .arrow {
            display: none;
        }
    }


    &__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__icon {
        width: 46px;
        height: 46px;
        display: inline-block;
        margin-bottom: 20px;
        color: $secondary-color;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .mini-text {
        background-color: #df9a625f !important;
    }

    .arrow {
        display: none;
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        justify-content: center;
        align-items: center;
        height: 50px;
        background-color: $white;
        border-radius: 50%;
        z-index: 1;
        @media screen and (min-width: $min-md) {
            display: flex;
            
        }

        svg {
            width: 19px;
            height: 19px;
        }
    }

    &:hover {
        .mini-text {
            background-color: $primary-color !important;
            color: $white;
        }
    }

}

.video-section{
    padding: 40px 0;     
    background-color: #F5F5FF;
    @media screen and (min-width: $min-md) {
        padding: 80px 0;        
    }

    &__inner{
        height: 350px;     
        max-width: 950px;
        margin-inline: auto;
        overflow: hidden;
        border-radius: 10px;
        @media screen and (min-width: $min-md) {
            height: 500px; 
        }

        img{
            width: 100%;
            height: 100%;
        }
    }
}