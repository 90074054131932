.home-banner {
    padding-top: 60px;
    // padding-bottom: 30px;
    min-height: 60vh;
    position: relative;
    background-color: $secondary-color;

    @media screen and (min-width: $min-md) {
        // padding-top: 0;
        padding-bottom: 80px;
    }

    &__services {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;

        @media screen and (min-width: $min-md) {
            font-size: 30px;
        }
    }

    .background-pattern {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 0;
        left: 0;
        z-index: 0;

        .bg-solid {
            display: block;
            background-color: $secondary-color;
            height: 100%;
            width: 100%;

            // @media screen and (min-width: $min-md) {
            //     width: 65%;
            // }
        }

        .bg-pattern-wrapper {
            position: absolute;
            right: 0;
            top: auto;
            bottom: 0;
            display: block;
            height: 300px;
            width: 100%;
            background-color: $primary-color;

            @media screen and (min-width: $min-md) {
                width: 35%;
                height: 100%;

            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }

    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        z-index: 1;
    }

    &__content-box {
        color: $title;
        width: 100%;
        padding-bottom: 60px;

        @media screen and (min-width: $min-md) {
            width: 40%;
            // padding-right: 50px;
            padding-bottom: 0;
        }

        p {
            color: $white;
        }
    }

    &__btn-wrapper {
        display: flex;
        align-items: center;
        margin-top: 38px;
    }

    &__img-box {
        width: 100%;
        height: 450px;
        position: relative;
        z-index: 1;

        @media screen and (min-width: $min-md) {
            width: 50%;
            height: 600px;

        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;

            // object-fit: contain;
            // object-position: left;
            // border-radius: 10px;
            @media screen and (min-width: $min-md) {
                // min-height: 500px;
            }
        }
    }

    &__logo {
        display: block;
        font-weight: 400;
        font-size: 20px;
        color: $black;

        @media screen and (min-width: $min-md) {
            font-size: 30px;
        }

        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;

            @media screen and (min-width: $min-md) {
                width: 35px;
                height: 35px;
                margin-right: 10px;

            }
        }
    }

    &__description {
        @media screen and (min-width: $min-md) {
            max-width: 420px;
        }
    }

    .index-module_type__E-SaG {
        &::after {
            font-weight: 100;
        }
    }

    .main-title {
        // color: $white;
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 38px;


        @media screen and (min-width: $min-md) {
            margin-bottom: 20px;
            font-size: 54px;
        }

        &>span {
            display: block;
            font-size: 50px;
        }
    }
}

.image-banner {
    padding-top: 60px;
    // padding-bottom: 30px;
    min-height: 60vh;
    position: relative;
    background-color: $secondary-color;

    @media screen and (min-width: $min-md) {
        padding-top: 80px;
        padding-bottom: 130px;
    }



    .background-pattern {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 0;
        left: 0;
        z-index: 0;

        .bg-solid {
            display: block;
            background-color: $secondary-color;
            height: 100%;
            width: 100%;
        }

        .bg-pattern-wrapper {
            position: absolute;
            right: 0;
            top: auto;
            bottom: 0;
            display: block;
            height: 300px;
            width: 100%;
            background-color: $primary-color;

            @media screen and (min-width: $min-md) {
                width: 35%;
                height: 100%;

            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }

    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        z-index: 1;
    }

    &__content-box {
        color: $white;
        width: 100%;
        padding-bottom: 60px;

        @media screen and (min-width: $min-md) {
            width: 40%;
            // padding-right: 50px;
            padding-bottom: 0;
        }

        p {
            color: $title;
        }
    }

    &__btn-wrapper {
        display: flex;
        align-items: center;
        margin-top: 38px;
    }

    &__img-box {
        width: 100%;
        height: 200px;
        margin-block: 50px;
        position: relative;
        z-index: 1;

        @media screen and (min-width: $min-md) {
            width: 50%;
            height: 400px;
            margin-block: 0;

        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;

            // object-fit: contain;
            // object-position: left;
            // border-radius: 10px;
            @media screen and (min-width: $min-md) {
                // min-height: 500px;
            }
        }
    }



    &__description {
        @media screen and (min-width: $min-md) {
            max-width: 420px;
        }
    }


    .main-title {
        // color: $white;
        margin-bottom: 25px;
        font-weight: 500;

        @media screen and (min-width: $min-md) {
            margin-bottom: 55px;
            font-size: 50px;
        }

        &>span {
            display: block;
            font-size: 50px;
        }
    }
}

.watch-video {
    display: flex;
    align-items: center;
    // margin-top: 50px;
    margin-left: 10px;

    @media screen and (min-width: $min-md) {
        margin-left: 20px;
    }

    &__title {
        display: inline-block;
        margin-right: 12px;
        font-size: 14px;

        @media screen and (min-width: $min-md) {
            font-size: 16px;
        }
    }

    .play-btn {
        background-color: transparent;
        border: 2px solid $title;
        color: $title;
        outline: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        transition: all 0.3s ease;
        padding: 0 4px;

        @media screen and (min-width: $min-md) {
            height: 30px;
            width: 30px;
        }

        svg {
            height: 20px;
            width: 20px;

            @media screen and (min-width: $min-md) {
                height: 24px;
                width: 24px;
            }
        }

        &:hover {
            background-color: $title;
            color: $secondary-color;
            box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
        }
    }
}

.banner-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid #9e9e9e33;

    @media screen and (min-width: $min-md) {
        flex-wrap: nowrap;
    }

    &__tag {
        width: 40px;
        height: 50px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &.SI {
            width: 130px;
            height: 60px;
        }

        &.ondc {
            width: 130px;
            height: 60px;
        }
    }

    &__icons {
        width: 70px;
        height: 50px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        margin-top: 20px;
        filter: drop-shadow(0 0 1rem white);

        @media screen and (min-width: $min-md) {
            width: 100px;
            height: 70px;
            margin-top: 0px;

        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

}

//product-hunt

.product-hunt {
    max-width: 330px;
    margin-right: 20px;

    a {
        display: flex;
        align-items: center;
        border: 2px solid $white;
        border-radius: 10px;
        padding: 16px 10px;
        transition: all 0.2s ease;

        @media screen and (min-width: 760px) {
            padding: 16px 15px;
        }

        &:hover {
            background-color: rgba($white, 0.2);
        }
    }
}

.product-hunt-log {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 100%;
    margin-right: 12px;

    @media screen and (min-width: 760px) {
        width: 30px;
        height: 30px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.product-hunt-title {
    color: $white;
    margin-right: 10px;
    width: calc(100% - 70px);

    .p-subtitle {
        display: block;
        font-size: 10px;
        line-height: 1.2;
        font-weight: 300;
        text-transform: uppercase;

        @media screen and (min-width: 760px) {
            // font-size: 15px; 
        }
    }

    .p-title {
        display: block;
        font-size: 14px;
        line-height: 1;
        font-weight: 600;

        @media screen and (min-width: 760px) {
            font-size: 16px;

        }
    }
}

.product-hunt-toll {
    display: flex;
    flex-direction: column;
    align-items: center;


    .triangle {
        display: block;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 16px solid $white;
    }

    .vote-count {
        margin-top: 6px;
        font-size: 10px;
        font-weight: 300;
        color: $white;
    }
}

.newletter {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin-top: 30px;

    @media screen and (min-width: $min-md) {
        margin-top: 50px;
        max-width: 470px;
    }

    input {
        border-style: none;
        margin-bottom: 0;
        appearance: none;
        width: calc(100% - 10px);
        height: 54px;
        font-family: inherit;
        font-size: 16px;
        color: $primary-color;
        padding-inline: 20px;
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        background: $white;
        margin-right: 10px;

        @media screen and (min-width: $min-md) {
            max-width: 335px;
        }

        &:focus-visible {
            outline: 0;
        }

    }
}

.small-loader {
    display: flex;
    width: 140px;
    justify-content: flex-start;
    padding-inline: 20px;
    align-items: center;
}

.new-banner {
    padding-top: 120px;
    padding-bottom: 30px;
    // min-height: 100vh;
    position: relative;
    background-color: $secondary-color;

    @media screen and (min-width: $min-md) {
        padding-top: 180px;
        padding-bottom: 130px;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        z-index: 1;
    }

    &__content-box {
        color: $white;
        width: 100%;
        padding-bottom: 110px;
        text-align: center;

        @media screen and (min-width: $min-md) {
            padding-bottom: 0;
        }

        p {
            color: $white;
        }
    }

    .newletter {
        margin-inline: auto;
    }

    .index-module_type__E-SaG {
        &::after {
            font-weight: 100;
        }
    }

    .main-title {
        color: $white;
        margin-bottom: 20px;
    }
}

//inner-page-banner
.inner-page-banner {
    background-color: $bg;
    padding: 50px 0;

    @media screen and (min-width: $min-md) {
        padding: 80px 0;
    }

    p {
        max-width: 700px !important;
    }
}

//help-banner

.help-banner {
    background-color: $bg;
    padding: 50px 0;

    @media screen and (min-width: $min-md) {
        padding: 100px 0;
    }

    &__wrapper {
        max-width: 550px;

        p {
            // color: $secondary-color;

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }
}