.events-banner {
    padding: 50px 0;
    background: rgb(63,185,251);
    background: radial-gradient(circle, rgba(63,185,251,1) 0%, rgba(157,70,252,1) 100%);

    @media screen and (min-width: $min-md) {
        padding: 80px 0;
    }

    &__inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__content{
        width: 100%;
        margin-bottom: 50px;
        @media screen and (min-width: $min-md) {
            width: 40%;
        }
        h1,p{
            color: white;
        }
    }

    &__form{
        width: 100%;
        padding: 20px 25px 60px;
        background-color: white;
        border-radius: 16px;
        @media screen and (min-width: $min-md) {
            width: 50%;
        }

        @media screen and (min-width: $min-xl) {
            width: 35%;
        }
    }
}
.event-crousel{
    padding: 50px 0;
    background: rgb(63,185,251);
    background: radial-gradient(circle, rgba(63,185,251,1) 0%, rgba(157,70,252,1) 100%);

    @media screen and (min-width: $min-md) {
        padding: 80px 0;
    }
    
    .title, .large-paragraph{
        color: white;
    }
}

.event-card{
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    overflow: hidden;
    // margin-right: 20px;
    margin: 20px 18px;
    width: calc(100% - 36px) !important;

    @media screen and (min-width: 760px) {
        margin: 60px 18px;
        min-height: 380px;
    }

    &__img{
        height: 200px;
        margin-bottom: 16px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &__title{
        font-size: 20px;
        margin-bottom: 16px;
        @media screen and (min-width: $min-md) {
            font-size: 24px;

        }
    }
}

//past-events
.past-events{
    padding: 50px 0;
    @media screen and (min-width: $min-md) {
        padding: 80px 0;
    }

    &__inner{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;

        @media screen and (min-width: $min-md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: $min-lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__card{
        padding: 20px;
        box-shadow:0 4px 24px 0 rgba(34,41,47,.2) ;
        border-radius: 1rem;
        display: flex;
        justify-content: space-between;
        position: relative;

        &:nth-child(1){
            
            flex-direction: column;
            justify-content: flex-start;

            @media screen and (min-width: $min-md) {
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;
            }

            .past-events__card-image{
                width: 100%;
                height: 400px;
            }

            .past-events__card-content{
                z-index: 1;
                padding: 20px;
                width: 100%;
                
            }
        }
    }

    &__card-image{
        height: 100%;
        width: 45%;
        @media screen and (min-width: $min-md) {
            width: 40%;

        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__card-content{
        width: 45%;
        @media screen and (min-width: $min-md) {
            width: 50%;
        }

        p{
            font-size: 14px;
        }
    }

    &__title{
        font-size: 20px;
        margin-bottom: 16px;
        @media screen and (min-width: $min-md) {
            font-size: 24px;

        }
    }
}

.load-more-button-wrapper{
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date{
    color: #546E7A;
    font-size: 12px;
    line-height: 1.1;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}