.video-block {
    padding-block: 40px;

    &__wrapper {
        overflow: hidden;
        position: relative;
        border-radius: 16px;
        height: 380px;

        @media screen and (min-width: $min-md) {
            height: 450px;
        }

        @media screen and (min-width: $min-lg) {
            height: 500px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .play-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        outline: none;
        border: none;
        cursor: pointer;
        animation: shadow-pulse 2s infinite;
        --box-shadow-color: rgb(255 255 255 / 25%);
        width: 60px;
        height: 60px;
        line-height: 1;
        border: 3px solid $white;
        background-color: $white;
        border-radius: 50%;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

        @keyframes shadow-pulse {
            0% {
                box-shadow: 0 0 0 0 var(--box-shadow-color);
            }

            70% {
                box-shadow: 0 0 0 40px transparent;
            }

            100% {
                box-shadow: 0 0 transparent;
            }
        }

        &:hover {
            color: $white;
            background-color: #FFFFFF00;
        }

        @media screen and (min-width: $min-md) {
            width: 100px;
            height: 100px;
        }
    }
}