.sticky-block {
    padding-block: 50px;
    position: relative;

    @media screen and (min-width: $min-md) {
        padding-block: 90px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__content {
        width: 100%;
        margin-bottom: 50px;

        @media screen and (min-width: $min-md) {
            width: 48%;
            padding-right: 30px;
            margin-bottom: 0;

        }

        @media screen and (min-width: $min-lg) {
            width: 45%;
            padding-right: 100px;
        }

        .title {
            margin-bottom: 25px;

            @media screen and (min-width: $min-md) {
                margin-bottom: 40px;
            }
        }

        ul {
            padding-left: 30px;

            li {
                color: $black;
                font-weight: 400;
                padding-left: 0;
                list-style: disc;

                &::marker {
                    color: $primary-color;
                    font-size: 1.5rem;
                    line-height: 1.2;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    .layer {
        margin-bottom: 2rem;

        @media screen and (min-width: $min-md) {
            margin-bottom: 20rem;

            &:last-child {
                margin-bottom: 1rem;
            }
        }

        @media screen and (min-width: $min-lg) {
            margin-bottom: 25rem;
        }
    }

    &__img-box {
        border: 1px solid #011E511A;
        box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.0784313725490196);
        border-radius: 10px;
        overflow: hidden;

        @media screen and (min-width: $min-md) {
            position: sticky;
            top: 160px;
            width: 50%;
            height: 600px;
        }

        @media screen and (min-width: $min-lg) {
            width: 53%;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &.reverse {
        .sticky-block__wrapper {
            @media screen and (min-width: $min-md) {
                flex-direction: row-reverse;
            }
        }

        .sticky-block__content {
            @media screen and (min-width: $min-md) {
                padding-right: 0;
                padding-left: 30px;
            }

            @media screen and (min-width: $min-lg) {
                padding-right: 0;
                padding-left: 100px;
            }
        }
    }
}