.testimonials{
    padding-block: 110px;

    @media screen and (min-width: $min-md) {
        padding-block: 110px;
    }

    &__grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        @media screen and (min-width: $min-md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $min-lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.testimonial-card{
    padding: 40px;
    @media screen and (min-width: $min-md) {
        padding: 40px;
        border-radius: 10px;
    }

    &:nth-child(odd){
        border: 1px solid #011e511a;
        box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.08);
        background-color: $bg;
    }

    &__content{
        margin-bottom: 50px;
        p{
            color: $text-grey;
            font-weight: 400;
        }
    }

    &__info{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .user-name{
        display: block;
        color: $secondary-color;
        font-size: 18px;
        font-weight: 600;
    }
    .user-post{
        display: block;
        color: $text;
        font-size: 16px;
        font-weight: 400;
    }
    .user-img{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}