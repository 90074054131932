.blogs-card{
    margin-bottom: 50px;

    &__link{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 10px;
        overflow: hidden;
        transition: all 0.7s cubic-bezier(.2, 1, .22, 1);
        border: 1px solid rgba(1, 30, 81, 0.1019607843);

        &:hover{
            box-shadow: 0px 20px 60px 0px rgba(1, 30, 81, 0.1);
            .blogs-card__img{
                img{
                    transform: scale(1.1);
                }
            }
        }
    }

    &__img{
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 10px;
        @media screen and (min-width: $min-md) {
            width: 270px;
            height: 240px;

        }

        // aspect-ratio: 1;
        img{
            width: 100%;
            height: 100%;
            transition: all 0.3s ease;
            // object-fit: cover;
        }
    }

    &__content{
        padding: 30px;
        width: 100%;

        @media screen and (min-width: $min-md) {
            width: calc(100% - 270px);
        }
    }

    .date-nd-time{
        color: $text-grey;
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 6px;
    }

    &__author-info{
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 6px;
    }
}