/* StickySection.css */
.sticky-section {
    padding: 80px 0;
    position: relative;
}


.sticky-feature {
    position: sticky;
    top: 70px;
    // height: 80vh;
    background-color: white;
    padding-top: 70px;
    @media screen and (min-width: $min-md) {
        padding-top: 80px;
        top: 80px;

    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__graphic {
        width: 100%;
        height: 40vh;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 50px;

        @media screen and (min-width: $min-md) {
            width: 60%;
            height: 60vh;
            margin-bottom: 0;

        }

        &.mobile{
            img {
                
                object-fit: contain;
                
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            overflow: hidden;
            @media screen and (min-width: $min-md) {
                width: 600px;
            }
        }
    }

    &__content {
        width: 100%;

        @media screen and (min-width: $min-md) {
            width: 36%;
        }
    }
}