.animated-section {
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__left {
        width: 100%;
        margin-bottom: 30px;
        @media screen and (min-width: $min-md) {
            width: 36%;
        }
    }

    &__anime-part {
        position: relative;
        width: 100%;

        min-height: 400px;

        @media screen and (min-width: $min-md) {
            min-height: 500px;
            width: 58%;
        }
    }

    .line {
        position: absolute;
        left: 50%;
        top: 60px;
        transform: translateX(-50%);
        width: 2px;
        height: 0;
        background: repeating-linear-gradient(to bottom,
                #d0d0d7,
                #d0d0d7 2px,
                transparent 2px,
                transparent 4px);
        animation: drawLine 4s linear forwards;
        animation-play-state: paused;
    }

    .card {
        width: 100%;
        height: 120px;
        border: unset;
        display: flex;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        position: relative;
        margin-bottom: 10px;
        background-color: transparent;
        flex-direction: row;

        &__child{
            width: 40%;
            height: 100%;
            background-color: #F5F5FF;
            padding: 20px;
            border-radius: 8px;
        }


        &::before {
            position: absolute;
            content: attr(data-count);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #F5F5FF;
            color: $primary-color;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;

        }
    }

    #card1 {
        justify-content: flex-end;
        opacity: 1;
    }

    #card2 {
        justify-content: flex-start;
    }

    #card3 {
        justify-content: flex-end;
        
    }

    #card4 {
        justify-content: flex-start;
        
    }
    #card5 {
        justify-content: flex-end;
        
    }

    @keyframes drawLine {
        0% {
            height: 0;
        }

        20% {
            height: 100px;
        }

        40% {
            height: 200px;
        }

        60% {
            height: 300px;
        }

        80% {
            height: 400px;
        }
        100% {
            height: 525px;
        }
    }


}