.faq{
    padding-block: 50px;
    background-color: $bg;

    @media screen and (min-width: $min-md) {
        padding-block: 110px;
    }

    .container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__content{
        width: 100%;
        margin-bottom: 50px;
        @media screen and (min-width: $min-md) {
            width: 40%;
            margin-bottom: 0;
        }
    }
    &__accordions{
        width: 100%;
        @media screen and (min-width: $min-md) {
            width: 53%;
        }
    }
}

.faq-accordion{
    .accordion-item{
        margin-bottom: 20px;
        border: none !important;
        border-radius: unset !important;
        background-color: transparent !important;

    }
    .accordion-button{
        padding: 20px;
        color: $primary-color;
        font-weight: 600;

        &::after{
            width: 16px;
            height: 16px;
            background-size: 16px;
            // color: $primary-color !important;
        }
    }
    .accordion-button:not(.collapsed){
        background-color: $white !important;
    }
    .accordion-header{
        border: 1px solid #011e511a;
        border-radius: 10px !important;
        overflow: hidden;
        box-shadow: 0px 20px 60px 0px rgba(0.9999999999999865, 30.000000000000014, 81, 0.08);
    }
    .accordion-collapse{
        background-color: transparent !important;
    }

    .accordion-body{
        color: $title;
    }
}