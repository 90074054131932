.pagination {
    justify-content: center;

    li {
        border: 1px solid rgba(1, 30, 81, 0.1019607843);
        margin-inline: 5px;
        border-radius: 6px;
        overflow: hidden;
        padding: 0;

        &::after{
            display: none;
        }

        a{
            padding: 10px 20px;
            display: block;

            &:hover{
                color: $white !important;
                background-color: $primary-color;
            }
        }

        &.active{
            color: $white;
            background-color: $primary-color;
        }
    }
}