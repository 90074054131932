.help-body {
    // padding-block: 90px;
    padding-bottom: 100px;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__navigation {
        width: 100%;
        position: sticky;
        top: 70px;
        z-index: 2;
        background-color: $white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 40px 0 20px;
        border-bottom: 1px solid #E7E7E7;

        @media screen and (min-width: $min-md) {
            padding-top: 100px;
            display: block;
            top: 100px;
            width: 30%;
            border-bottom:unset;

        }
    }

    &__content {
        width: 100%;

        @media screen and (min-width: $min-md) {
            width: 70%;
            padding-left: 50px;
        }
    }

    &__nav {
        display: block;
        color: $title;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 15px;
        position: relative;
        width: max-content;
        transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        padding-bottom: 2px;

        &:hover {
            color: $primary-color;
        }

        &.active {
            color: $primary-color;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary-color;
            }
        }
    }

    .questions-section {
        padding-block: 100px 50px;
        border-bottom: 1px solid #E7E7E7;

        &:last-child {
            padding-bottom: 0;
            border-bottom: unset;
        }

        @media screen and (min-width: $min-md) {
            padding-block: 100px;
        }
    }
}