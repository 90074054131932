.our-ai {
    background-color: #222;
    padding-bottom: 60px;

    @media screen and (min-width: 760px) {
        padding-bottom: 80px;
    }
    &__wrapper {
        padding-block: 60px 0;

        @media screen and (min-width: 760px) {
            padding-block: 88px 0;
        }
    }

    .mini-text{
        color: $white;
        background-color: #936BFF1A;
    }

    &__img {
        width: 100%;
        height: 200px;

        @media screen and (min-width: 760px) {
            height: 538px;

        }

        img {
            width: 100%;
            height: 100%;
            // object-fit: contain;
        }
    }
    h2, p{
        color: $white;
    }
}