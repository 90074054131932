.recognitions{
    padding-block: 80px 40px;

    &__icons{
        // height: 120px;
        height: 80px;

        padding-inline:10px;
        display: flex !important;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &.neo{
            height: 60px;
        }
        &.sql-lite{
            height: 100px;
        }
        &.snowflakes{
            height: 70px;
        }
        &.sap{
            height: 70px;
        }
        &.impala{
            height: 100px;
        }

        // &.founder-hub{
        //     height: 90px;
        // }

        // &.seed-fund{
        //     height: 100px;
        // }
    }
}