.offers-banner {
    position: fixed;
    top: 70px;
    width: 100%;
    z-index: 5;
    background: #FFE082;
    overflow: hidden;

    @media screen and (min-width: $min-md) {
        top: 80px;
    }

    &__inner {
        padding: 10px 0;
        display: flex;
        justify-content: center;

        h2 {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
        }
    }
}