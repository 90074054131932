.feedback{
    padding-top: 70px;
    margin-top: 90px;
    border-top: 1px solid #e7e7e7;

    .feedback-form{
        margin-top: 30px;
    }
    .form-control{
        background-color: $bg;
        padding: 6px 20px;
        height: 50px;

        &::placeholder{
            color: $text;
        }
    }

    textarea{
        min-height: 150px;
    }
}